:root {
  --avaya_red:rgb(218,41,28);
  --avaya_blue:rgb(0,78,108);
  --avaya_blue_light:rgb(27,119,175);
  --avaya_teal: rgb(10,142,155);
  --avaya_grey: rgb(230,230,230);
  --avaya_grey_dark: rgb(51,51,51);
  --avaya_grey_light: rgb(245,245,245);
  --cct_turquoise: #2E9FDB;
  --color_shadow: rgb(192,192,192);
  --font_family: "Verdana";
  --font_size_normal: 12px;
  --shadow_main: 3px 3px 10px 0px var(--color_shadow);
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

.cct-chat-widget {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: self-end;
  justify-content: flex-end;
  z-index: 100000;
}

.cct-chat-widget.cct-chat-widget-opened {
  max-width: 380px;
  max-height: 650px;
  height: calc(100% - 40px);
}

.cct-chat-widget.cct-chat-widget-opened:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #000000bf;
}

.cct-chat-widget .disabled {
  opacity: .5;
}

button {
  border: none;
  cursor: pointer;
}

button:hover {
  transition: 2s;
}

@media (max-height: 690px) {
  .cct-chat-widget.cct-chat-widget-opened {
    flex-direction: row;
    max-width: 452px;
  }
}